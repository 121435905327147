<template>
  <div class="roadmap" @mousemove="updateTooltipPosition">
    <h1>ROADMAP</h1>
    <img src="../assets/roadmap.png" alt="Roadmap" usemap="#roadmap-map" />
    <map name="roadmap-map">
      <!-- Test area 1: Top left corner -->
      <area
        shape="rect"
        coords="450,400,600,550"
        @mouseover="showTooltip('Blue balling in progress...')"
        @mouseleave="hideTooltip"
      />
      <!-- Test area 2: Bottom right corner -->
      <area
        shape="rect"
        coords="250,580,400,730"
        @mouseover="showTooltip('Blue balling in progress...')"
        @mouseleave="hideTooltip"
      />
    </map>
    <div
      v-if="tooltip.visible"
      class="tooltip"
      :style="{ top: tooltip.top + 'px', left: tooltip.left + 'px' }"
    >
      <h2>{{ tooltip.title }}</h2>
      <p>{{ tooltip.description }}</p>
    </div>
    <div class="always-show-roadmap-1">
      <h2>The Beginning</h2>
      <p>● Unveiling of Website and the Face of $GONAD</p>
      <p>● Discord and Twitter Community Programs</p>
      <p>● Preparation of Tokenomics</p>
    </div>
    <div class="always-show-roadmap-2">
      <h2>Testes-net</h2>
      <p>● $GONAD testing</p>
      <p>● ?</p>
      <p>● ?</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "RoadMap",
  data() {
    return {
      tooltip: {
        visible: false,
        top: 0,
        left: 0,
        title: "",
        description: "",
      },
    };
  },
  methods: {
    updateTooltipPosition(event) {
      // Update tooltip position based on mouse position
      this.tooltip.top = event.clientY + 10; // Add an offset to avoid overlapping with the cursor
      this.tooltip.left = event.clientX + 10; // Add an offset to avoid overlapping with the cursor
    },
    showTooltip(title, description) {
      // Show tooltip with provided title and description
      this.tooltip.title = title;
      this.tooltip.description = description;
      this.tooltip.visible = true;
    },
    hideTooltip() {
      // Hide tooltip
      this.tooltip.visible = false;
    },
  },
};
</script>

<style scoped>
.roadmap {
  position: relative;
  margin-top: 2%;
  overflow: hidden;
}

.tooltip {
  position: fixed;
  background-color: rgba(46, 169, 206, 0.8);
  color: black;
  padding: 1vw;
  border-radius: 1vw;
  z-index: 999;
  border-radius: 1.5vw;
  border: 0.2vw solid rgb(255, 255, 255);
}

.tooltip p {
  font-size: 1vw;
}

.tooltip h2 {
  font-size: 1vw;
}
.roadmap img {
  width: 100%;
}

.always-show-roadmap-1,
.always-show-roadmap-2 {
  position: absolute;
  background-color: rgba(46, 169, 206, 0.8);
  padding: 2%;
  border-radius: 1.5vw;
}

.always-show-roadmap-1 {
  top: 70%;
  left: 56%;
  border: 0.2vw solid rgb(255, 255, 255);
}

.always-show-roadmap-2 {
  top: 45%;
  left: 75%;
  border: 0.2vw solid rgb(255, 255, 255);
}

h1 {
  font-size: 4vw;
  color: white;
  margin-bottom: 4vw;
}

h1:hover {
  color: rgb(46, 169, 206);
  transform: scale(1.2);
}

h2 {
  font-size: 1.5vw;
  color: white;
  text-align: center;
  margin-top: 2%;
  margin-bottom: 2%;
}

p {
  font-size: 1vw;
  color: rgb(248, 247, 247);
  text-align: left;
  margin-bottom: 0.4vw;
}
</style>
