<template>
  <div class="faq-section">
    <div class="left-content">
      <h2>FAQs</h2>
      <div v-for="(faq, index) in faqs" :key="index" class="faq-item">
        <div class="question" @click="toggleAnswer(index)">
          {{ faq.question }}
          <span v-if="faq.open" class="toggle-icon">-</span>
          <span v-else class="toggle-icon">+</span>
        </div>
        <div v-if="faq.open" class="answer">
          {{ faq.answer }}
        </div>
      </div>
    </div>
    <div class="right-content">
      <img src="../assets/tea-bag.gif" alt="FAQs" height="auto" />
    </div>
  </div>
</template>

<script>
export default {
  name: "FaqInfo",
  data() {
    return {
      faqs: [
        {
          question: "The Birth of $GONAD?",
          answer:
            "In the beginning, Keonad, assisted by archangel Eunad, created the heavens and the blockchain. The Monad blockchain was formless and empty - darkness was over the surface of its layers, and the Spirit of Keonad was hovering over the very first transactions on the devnet. \n\nKeonad said: “Let us make Monad in our image, in our likeness, so that Monad may rule over all the chains, over the farms and all the wild degens, and over all the creatures that move on-chain.” And so, Keonad said: “Let there be speed and scalability”, and there was speed and scalability. Keonad called the speed “10,000 TPS,” and the scalability, he called “parallel executions.” And there was evening, and there was morning — the first day. \n\nKeonad grew impatient - “Man...this shit is taking too long. I ain’t waiting 7 days. Let me do something about it ”, and created the $GONAD in the year 1 B.M. (Before Monad). \n\n$GONAD was the singularity that gave birth to the Monad ecosystem, flippening Monad to #1 on Coinmarketcap. Keonad saw all that he had made, and it was very good.",
          open: false,
        },
        {
          question: "Why should you hold $GONAD?",
          answer: "The longer you hold, the better the NUT",
          open: false,
        },
        {
          question: "What’s next for $GONAD?",
          answer:
            "We’re blue balling you for the moment, but let us cook for Phase 2…the Test-tes net. And of course, Phase 3 - Mainnet ",
          open: false,
        },
        // Add more FAQs as needed
      ],
    };
  },
  methods: {
    toggleAnswer(index) {
      this.faqs[index].open = !this.faqs[index].open;
    },
  },
};
</script>

<style scoped>
.faq-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  padding-bottom: 2%;
  margin-top: 2%;
}

.right-content {
  display: flex;
  align-items: center;
  width: 50%; /* Adjust width as needed */
}

.right-content img {
  width: 100%;
}

.left-content {
  width: 50%; /* Adjust width as needed */
}

h2 {
  text-align: center;
  color: #ffffff;
  font-size: 2vw; /* Responsive font size */
}

.faq-item {
  margin-bottom: 2%;
  color: #000000;
}

.question {
  cursor: pointer;
  background-color: #f0f0f0;
  padding: 2%;
  border-radius: 2vw; /* Responsive border radius */
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 80%;
  font-size: 1.1vw; /* Responsive font size */
  margin-left: 3vw;
  margin-top: 1vw;
}

.question:hover {
  background-color: rgb(46, 169, 206);
  padding: 2.5%;
  transition-duration: 0.2s;
  color: white;
}

.toggle-icon {
  font-weight: bold;
}

.answer {
  margin-top: 2%;
  color: #ffffff;
  white-space: pre-wrap;
  text-align: left;
  margin-left: 3vw;
}

img {
  width: 40%;
}
</style>
