<template>
  <div class="header">
    <div class="left-content">
      <h1>GONAD</h1>
    </div>
    <div class="right-content">
      <nav>
        <a href="https://twitter.com/Gonad_Monad" target="_blank" rel="noopener"
          >Twitter</a
        >
        <a href="https://gonadmonad.com" target="_blank" rel="noopener"
          >Discord</a
        >
        <a href="#FaqInfo" @click="scrollToFAQs">FAQs</a>
        <a href="#Roadmap" @click="scrollToRoadmap">Roadmap</a>
      </nav>
    </div>
  </div>
</template>

<script>
export default {
  name: "HeaderSection",
  methods: {
    scrollToFAQs() {
      const faqSection = document.getElementById("FaqInfo");
      faqSection.scrollIntoView({ behavior: "smooth" });
    },
    scrollToRoadmap() {
      const roadmapSection = document.getElementById("Roadmap");
      roadmapSection.scrollIntoView({ behavior: "smooth" });
    },
  },
};
</script>

<style scoped>
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2%;
  position: relative;
}

.left-content {
  width: 20%; /* Adjust width percentage */
  text-align: left;
}

.right-content {
  width: 80%; /* Adjust width percentage */
}

h1 {
  margin: 0;
  color: #ffffff;
  font-size: 3vw; /* Adjust font size percentage */
}

h1:hover {
  color: rgb(46, 169, 206);
  transform: scale(1.1);
}

nav {
  display: flex;
  justify-content: center;
}

nav a {
  margin: 0 3%;
  text-decoration: none;
  color: #ffffff;
  font-size: 1.5vw; /* Adjust font size percentage */
}

nav a:hover {
  color: rgba(46, 169, 206, 0.8);
  transform: scale(1.1);
}
</style>
