<template>
  <div class="flexbox">
    <div class="left-content">
      <div class="same-row">
        <h1>WELCOME TO</h1>
        <h2>$GONAD</h2>
      </div>
      <h1>THE NUT AND BALL OF MONAD</h1>
      <p style="margin-top: 1vw">
        Sack up with the first and best memecoin in the Monad ecosystem
      </p>
      <div class="icons">
        <a href="https://twitter.com/Gonad_Monad" target="_blank" rel="noopener"
          ><img
            src="../assets/X.png"
            style="float: left; width: 3vw; height: 3vw; margin-right: 2vw"
          />
        </a>
        <a href="https://gonadmonad.com" target="_blank" rel="noopener"
          ><img
            src="../assets/discord.png"
            style="float: left; width: 3vw; height: 3vw; margin-right: 2vw"
          />
        </a>
      </div>

      <button class="buy-button" style="margin-top: 1vw">
        BUY $GONAD (coming soon)
      </button>
    </div>
    <div class="right-content">
      <img src="../assets/header.gif" alt="Gonad" />
    </div>
  </div>
</template>

<style scoped>
.flexbox {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  padding: 2%;
}

.left-content {
  width: 60%;
  text-align: left;
}

.left-content h1,
.left-content p,
.left-content button {
  text-align: left;
}

.buy-button {
  background-color: white;
  color: black;
  border: 1px solid black; /* Optional: add border for better visibility */
  padding: 1vw 2vw; /* Adjust padding as needed */
  border-radius: 1vw; /* Optional: add rounded corners */
  cursor: pointer; /* Optional: show pointer cursor on hover */
  font-size: 1.2vw; /* Responsive font size */
  width: auto; /* Adjust width automatically */
  margin-top: 1vw; /* Adjust margin-top */
  font-family: "Grandstander", cursive, sans-serif;
}

.buy-button:hover {
  background-color: rgb(46, 169, 206);
  color: white;
  transform: scale(1.2);
}

.icons {
  display: flex; /* Make the icons flex items */
  align-items: center; /* Align items vertically */
  margin-top: 2vw;
  margin-bottom: 1vw;
}

.icons a {
  margin-right: 0vw; /* Add margin between the icons */
}

.same-row {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 1vw; /* Adjust margin-bottom */
}

h1,
h2 {
  font-size: 2vw; /* Responsive font size */
  margin-right: 2%;
}

h2 {
  background-color: rgb(46, 169, 206);
  border-radius: 2vw;
  padding: 0.5vw;
  margin: 0;
}

.right-content {
  width: 40%;
  margin-top: 2%;
}

.right-content img {
  width: 100%;
  height: auto; /* Maintain aspect ratio */
}

p {
  font-size: 1.5vw; /* Responsive font size */
}

img {
  margin-right: 2vw;
}

img:hover {
  transform: scale(1.1);
}

/* Remove any hover effects for h1 and h2 */
h1:hover,
h2:hover {
  color: inherit;
  transform: none;
}
</style>
