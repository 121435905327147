<template>
  <div id="app-container">
    <div id="app">
      <RouterView />
    </div>
  </div>
</template>

<script>
export default {
  name: "App",
};
</script>

<style>
* {
  margin: 0;
}
@import url("https://fonts.googleapis.com/css?family=Grandstander");
@import url("https://fonts.googleapis.com/css?family=Mali");
@import url("https://fonts.googleapis.com/css?family=Patrick%20Hand");

#app-container {
  max-width: 100%; /* Adjust as needed */
  margin: 0 auto; /* Center the container */
}

#app {
  font-family: "Grandstander", cursive, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #ffffff;
  background: transparent;
}
</style>
