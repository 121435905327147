<template>
  <div class="meme-section">
    <div class="left-content">
      <img src="../assets/meme-section.gif" alt="faq" />
    </div>
    <div class="right-content">
      <h1>Get BALLS DEEP with Bronad on Monad</h1>
    </div>
  </div>
</template>
<script>
export default {
  name: "MemeSection",
  props: {},
};
</script>
<style scope>
.meme-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  margin-bottom: 2vw;
}

.right-content {
  width: 50%;
  text-align: left;
  font-size: 2vw;
}

.left-content {
  width: 50%;
}

.left-content img {
  width: 100%;
}

.left-content img:hover {
  transform: scale(1.1);
}
</style>
