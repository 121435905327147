<template>
  <div class="footer">
    <div class="left-content">
      <p>Proudly Powered by Monad</p>
    </div>
    <div class="right-content">
      <a href="https://gonadmonad.com" target="_blank" rel="noopener"
        >Dexscreener</a
      >
      <a href="https://gonadmonad.com" target="_blank" rel="noopener"
        >MonadScan</a
      >
      <a href="https://twitter.com/Gonad_Monad" target="_blank" rel="noopener"
        >Twitter</a
      >
      <a href="https://gonadmonad.com" target="_blank" rel="noopener"
        >Discord</a
      >
    </div>
  </div>
</template>

<script>
export default {
  name: "FooterInfo",
  props: {},
};
</script>

<style scoped>
.footer {
  color: #fff;
  padding: 4%; /* Increase padding for taller footer */
  text-align: left;
  display: flex;
  justify-content: space-between;
  font-size: 1.5vw; /* Responsive font size */
  position: relative;
  background: linear-gradient(
    to bottom right,
    rgba(0, 0, 50, 0.5),
    rgba(128, 0, 128, 0.5)
  );
  border-radius: 1vw; /* Responsive border radius */
  background-image: url("../assets/footer.png");
  background-size: cover;
}

.left-content {
  flex: 1; /* Take up remaining space */
  width: 40%;
  font-size: 2vw; /* Removed */
}

.right-content {
  display: flex;
  align-items: center;
  width: 60%;
  overflow: hidden; /* Removed */
}

.right-content a {
  text-decoration: none;
  color: #ffffff;
  padding-left: 3vw;
  font-size: 1.2vw;
}

a:hover {
  color: rgba(46, 169, 206, 0.8);
  transition-duration: 0.2s;
}
</style>
