<template>
  <div class="page">
    <div class="app">
      <HeaderSection />
      <IntroducionInfo msg="WELCOME TO $GONAD, THE NUT AND BALL OF MONAD" />
      <HowToBuy />
      <RoadMap id="Roadmap" />
      <FaqInfo id="FaqInfo" />
      <MemeSection />
      <FooterInfo />
    </div>
  </div>
</template>

<script>
import HeaderSection from "@/components/HeaderSection.vue";
import IntroducionInfo from "../components/IntroducionInfo.vue";
import HowToBuy from "../components/HowToBuy.vue";
import RoadMap from "../components/RoadMap.vue";
import FaqInfo from "../components/FaqInfo.vue";
import FooterInfo from "../components/FooterInfo.vue";
import MemeSection from "../components/MemeSection.vue";

export default {
  name: "App",
  components: {
    HeaderSection,
    IntroducionInfo,
    HowToBuy,
    RoadMap,
    FaqInfo,
    MemeSection,
    FooterInfo,
  },
};
</script>

<style>
* {
  margin: 0;
}
@import url("https://fonts.googleapis.com/css?family=Grandstander");
@import url("https://fonts.googleapis.com/css?family=Mali");
@import url("https://fonts.googleapis.com/css?family=Patrick%20Hand");

.page {
  background: url("../assets/Gonad-Web-BG-2.png") center center fixed;
  background-size: cover;
}
.app {
  font-family: "Grandstander", cursive, sans-serif;
  width: 85%;
  margin-left: 7.5%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #ffffff;
  padding: 5px;
}
</style>
