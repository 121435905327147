<template>
  <div class="flexbox">
    <div class="left-content">
      <img src="../assets/sperm.png" alt="sperm" />
    </div>
    <div class="right-content">
      <h1>HOW TO BUY</h1>
      <div class="image-text-container-1">
        <img
          src="../assets/icon1.png"
          style="float: left; width: 3.5vw; height: 3.5vw; margin-right: 2vw"
        />
        <p>
          Add Monad chain to your Metamask using chainlist.org (coming soon)
        </p>
      </div>
      <div class="image-text-container-2">
        <img
          src="../assets/icon2.png"
          height="60"
          style="float: left; width: 3.5vw; height: 3.5vw; margin-right: 2vw"
        />
        <p>Click on BUY $GONAD to buy on DEX (coming soon)</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "HowToBuy",
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.flexbox {
  display: flex;
  justify-content: space-between;
  align-items: left;
  padding: 2% 3%; /* Adjust padding as needed */
}

.left-content {
  width: 40%;
}

.left-content img {
  max-width: 100%; /* Ensure image doesn't exceed container width */
  height: auto; /* Maintain aspect ratio */
}

.right-content {
  width: 60%; /* Adjust width for larger screens */
  padding-left: 5%; /* Adjust padding for larger screens */
  margin-top: 3vw;
}

.image-text-container-1 {
  display: flex;
  align-items: center; /* Align items vertically */
  text-align: left;
  margin-bottom: 2vw;
}

.image-text-container-2 {
  display: flex;
  align-items: center; /* Align items vertically */
  text-align: left;
}

p {
  font-size: 1.2vw; /* Responsive font size */
}

h1 {
  color: #ffffff;
  font-size: 2vw; /* Responsive font size */
  text-align: center;
  margin-bottom: 2vw;
}
</style>
